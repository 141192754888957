var coll = document.getElementsByClassName("insurance-container");
var i;

for (i = 0; i < coll.length; i++) {

	coll[i].addEventListener("click", function(e) {
		
		if(e.target && e.target.classList.contains('c-collapse__btn')){

			//do something
			e.target.classList.toggle("active");
			var content = e.target.nextElementSibling;
			if (content.style.maxHeight) {
				content.style.maxHeight = null;
				content.style.borderBottom = "none";
				content.style.padding = "0px";
			} else {
				content.style.maxHeight = "315px";
				content.style.borderBottom = "1px solid #EAEAEA";
				content.style.padding = "25px 0px";
			}
		}
		   		
	});
}