// Function to check if the element with id="esos-content" exists
function isEsosContentPresent() {
	return document.getElementById("esos-content") !== null;
  }
  
  // Load the ESOS_CONFIG script and ESOS widget only if the element exists
  if (isEsosContentPresent()) {
	var ESOS_CONFIG = {
		name: "Kizi",
		host: "www.kizi.nl",
		slug: "",
		funnel: "car",
		language: "",
		baseUrl: "https://api2.overstappen.nl",
		authKey: "",
		displayAfmLicenseInfo: true,
		enableAfmLicenseInfoLink: true,
		enableMailComparisonOption: true,
		displayPoweredByLogo: false,
		enableExtraInfoProvider: false,
		phoneNumber: "020 11 111 111",
		customOpeningTimes: "Open from 9.00 to 18.00",
		emailAddressSupport: "support-email-sample.com",
		hideValidationIcons: false,
		pages: {
		  titleBar: {
			background: "#fff",
			hasArrow: false,
			useHeading: true
		  }
		},
		theme: {
		  fonts: {
			bodyFont: "'Roboto', sans-serif",
			headingFont: "'Roboto', sans-serif"
		  },
		  colors: {
			brand: "#004970",
			primary: "#FF6B00",
			bodyColor: "#303236",
			bodyBg: "transparent",
			labelColor: "#525a66",
			info: "#6c7787",
			warning: "#fa4d06",
			success: "#00C49A",
			heading1: "#fff",
			heading2: "#fff"
		  },
		  components: {
			buttonPrimary: {
			  padding: "0.825em 0.5em"
			},
			fieldBackgroundColor: "#fff",
			infoLabel: {
			  backgroundColor: "#ffb400"
			},
			icons: {
			  check: {
				color: "#00C49A"
			  }
			},
			car: {
			  app: {
				background: ""
			  },
			  coverageBlock: {
				selectedCoverageBorderColor: "#004970",
				selectedCoverageBorderTextColor: "#fff"
			  }
			}
		  },
		  forms: {
			field: {
			  borderColor: "#a9aeb9"
			}
		  }
		}
	};
  
	var script = document.createElement("script");
	script.src = "https://comparators.overstappen.nl/embed.js";
	script.async = true;
	document.body.appendChild(script);
  }