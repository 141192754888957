import { ServerRequest } from '@nutshell/core';

const form = document.querySelector('[data-funnel-form="addition-information"]');
const button = document.querySelector('[data-funnel-form="addition-information"] button[type=submit]');

if ( form ) {

    form.addEventListener('valid', (event) => {

        event.preventDefault();

        const rqst = new ServerRequest();
        button.disabled =true;

        rqst.submit('[data-funnel-form="addition-information"]', 'Funnel:additionalInformation').then( ( { result } ) => {

            if ( result ) {
                button.disabled = false;
                window.location.href = '/check/verzekeringen-vergelijken/';
            }
            else {
                button.disabled = false;

                $(button).removeClass('c-btn--loading');
                $(button).addClass('c-btn--success').html('Aanvraag verstuurd!');

                form.reset();

                window.setTimeout(function(){
                    $(button).removeClass('c-btn--success').html('Aanvragen');
                }, 6000);
            }

        }).catch( (e) => {
            button.disabled = false;
            alert('TODO: show error notification');
        });
    });
}
