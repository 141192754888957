import { ServerRequest } from '@nutshell/core';

const form = document.querySelector('[data-funnel-form="addition-data"]');
const button = document.querySelector('[data-funnel-form="addition-data"] button[type=submit]');
const input = document.querySelector('[name=code]');
const startDate = document.querySelector('[name="start-date-insurance"]');

var dateIsValid = false;

/**
 * Submit form if code is valid and form is valid
 */
if ( form ) {

    form.addEventListener('valid', (event) => {

        event.preventDefault();

        if ( dateIsValid ) {

            const rqst = new ServerRequest();

            button.disabled = true;
            button.classList.add('c-btn--loading');
            button.innerHTML = '<span class="c-loader"></span>';

            rqst.submit('[data-funnel-form="addition-data"]', 'Funnel:saveAdditionalData').then( (response) => {

                rqst.post('Funnel:post').then( (response) => {

                    button.disabled = false;
                    window.location.href = '/aanvragen/slotvragen/';

                }).catch( (e) => {

                    button.classList.add('c-btn--alert');
                    button.classList.remove('c-btn--loading');
                    button.innerText = 'Formulier kan niet worden verzonden';

                    setTimeout( () => {
                        button.innerText = 'Volgende stap';
                        button.classList.remove('c-btn--alert');
                        button.disabled = false;
                    }, 6000);

                });

            }).catch( (e) => {

                button.classList.add('c-btn--alert');
                button.classList.remove('c-btn--loading');
                button.innerText = 'Formulier kan niet worden verzonden';

                setTimeout( () => {
                    button.innerText = 'Volgende stap';
                    button.classList.remove('c-btn--alert');
                    button.disabled = false;
                }, 6000);
            });
        }
    });

    form.addEventListener('invalid', function() {

        button.classList.add('c-btn--alert');
        button.classList.remove('c-btn--loading');
        button.innerText = 'Formulier is niet volledig ingevuld';

        setTimeout( () => {
            button.innerText = 'Volgende stap';
            button.classList.remove('c-btn--alert');
            button.disabled = false;
        }, 6000);
    });
}

/**
 * Check if startdate is a valid date
 */
if ( startDate ) {

    startDate.addEventListener('blur', (event) => {

        const rqst = new ServerRequest();
        const errorField = document.querySelector('[data-form-error-for="start-date-insurance"]');

        rqst.post('Funnel:validateStartDate', { startDate: event.target.value } ).then( (response) => {

            dateIsValid = true;

            removeCustomError(startDate);

        }).catch( ( {status, result, message} ) => {

            dateIsValid = false;

            if ( status == 403 ) {
                errorField.innerHTML = 'Ingangsdatum moet tussen ' + result.from + ' en '  + result.till + ' liggen.';
            }
            else {
                errorField.innerHTML = 'Ingangsdatum kon niet worden gevalideerd.';
            }

            setCustomError(startDate);
        });
    });

    if ( startDate.value != '' ) {
        dateIsValid = true;
    }
}

function removeCustomError(element) {

    element.setAttribute('data-valid', '');
    element.setAttribute('aria-valid', true);

    element.removeAttribute('data-invalid');
    element.removeAttribute('aria-invalid');

    element.parentNode.classList.add('is-valid');
    element.parentNode.classList.remove('is-invalid');

    const errorField = element.parentNode.querySelector('[data-form-error-for]');

    if ( errorField ) {
        errorField.classList.remove('is-visible');
    }
}

function setCustomError(element) {

    element.setAttribute('data-invalid', '');
    element.setAttribute('aria-invalid', true);

    element.removeAttribute('data-valid');
    element.removeAttribute('aria-valid');

    element.parentNode.classList.add('is-invalid');
    element.parentNode.classList.remove('is-valid');

    const errorField = element.parentNode.querySelector('[data-form-error-for]');

    if ( errorField ) {
        errorField.classList.add('is-visible');
    }
}
