import { ServerRequest} from '@nutshell/core';
import Validator from '@nutshell/core/src/Validator';

window.container = document.querySelector('[data-insurance-container]');
window.loader = document.querySelector('[data-loader-container]');


const risks = document.querySelectorAll('[name=risk]');
const newvalues = document.querySelectorAll('[name=newvalue]');
const carouselItems = document.querySelectorAll('.c-car-loader__carousel-item');

var interval = null,
    index = null,
    resultHTML = null;

/**
 * Reload results if risk is changed
 */
if ( risks ) {

    risks.forEach( (risk) => {

        risk.addEventListener('change', () => {

            const rqst = new ServerRequest();
            const selected = document.querySelector('[name=risk]:checked');

            rqst.post('Funnel:setRisk', { risk: selected.value }).then( ( { result }) => {
                load();
            }).catch( (e) => {});
        });
    });
}

/**
 * Reload results if newvalue filter is changed
 * Copy PASTE
 */
if ( newvalues ) {

    newvalues.forEach( (newvalue) => {

        newvalue.addEventListener('change', () => {

            const rqst = new ServerRequest();
            const selected = document.querySelector('[name=newvalue]:checked');

            rqst.post('Funnel:setNewvalue', { newvalue: selected.value }).then( ( { result }) => {
                load();
            }).catch( (e) => {});
        });
    });
}

/**
 * Load data
 */
function loadInsurances(container) {

    const rqst = new ServerRequest();

    rqst.post('Funnel:getInsurances').then( ( { result }) => {

        resultHTML = result;

    }).catch( (e) => {});
}

window.loadContainer = function() {

    /**
     * Reset loader
     */
    window.loader.classList.remove('hide');
    window.container.innerHTML = '';

    interval = null;
    index = null;
    resultHTML = null;

    loadInsurances();
    disableOptions();
    nextItem();

    interval = setInterval( () => {
        nextItem();
    }, 800);
};

/**
 * Autofill results by pageload
 */
if ( window.container && window.loader ) {

    window.container.load = window.loadContainer();
}
else if( window.container ) {

    const quotationForm = document.querySelector('#form-quotation');

    if ( quotationForm ){
        disableOptions();
        quotationForm.addEventListener('valid', submitQuotation);
    }
}

/**
 * Show loading text
 */
function nextItem() {

    index = ( index == null ) ? 0 : index + 1;

    if( typeof carouselItems[index] !== "undefined" ) {
        carouselItems[index].classList.add('show');
    }

    if( typeof carouselItems[index -1] !== "undefined" ) {
        carouselItems[index -1].classList.remove('show');
    }

    if ( index >= carouselItems.length && resultHTML ) {

        const formInit = document.querySelector('.form-init-loaded');

        clearInterval(interval);

        window.container.innerHTML = resultHTML;
        window.loader.classList.add('hide');
        enableOptions();

        /**
         * Assign events on the html from the xhr call.
         */
        const buttons = document.querySelectorAll('[data-insurance-button]');
        const formButton = document.querySelector('#trigger-form-quotation');
        const quotationForm = document.querySelector('#form-quotation');

        if ( buttons ) {
            buttons.forEach( (button) => {
                button.addEventListener('click', selectInsurance);
            });
        }

        if ( quotationForm ) {

            const validator = new Validator();
            validator.create(quotationForm);

            quotationForm.addEventListener('valid', submitQuotation);
        }

        if ( formButton ) {
            formButton.addEventListener('click', showForm);
        }
    }
}

function selectInsurance( event ) {

    event.preventDefault();

    const rqst = new ServerRequest();
    const btn = event.target;
    const id = btn.getAttribute('data-insurance-id');

    disableButtons();

    btn.classList.add('c-btn--loading');
    btn.innerHTML = '<span class="c-loader"></span>';

    rqst.post('Funnel:setCharacteristic', {characteristic: id}).then( ( { result } ) => {

        setTimeout( () => {
            window.location.href = '/aanvragen/meer-dekking';
        }, 1000);

    }).catch( (e) => {

        enableButtons();

        btn.classList.add('c-btn--alert');
        btn.classList.remove('c-btn--loading');
        btn.innerText = 'Aanvraag niet gelukt';

        setTimeout( () => {
            btn.innerText = 'Aanvraag';
            btn.classList.remove('c-btn--alert');
            btn.disabled = false;
        }, 6000);
    });
}


function disableOptions() {

    const options = document.querySelectorAll('.radio-filter');

    options.forEach( (option) => {
        option.disabled = true;
    });
}

function enableOptions() {

    const options = document.querySelectorAll('.radio-filter');

    options.forEach( (option) => {
        option.disabled = false;
    });
}

function disableButtons() {
    const options = document.querySelectorAll('[data-insurance-button]');

    options.forEach( (option) => {
        option.disabled = true;
    });
}

function enableButtons() {
    const options = document.querySelectorAll('[data-insurance-button]');

    options.forEach( (option) => {
        option.disabled = false;
    });
}

function showForm() {
    const formShow = document.querySelectorAll('[data-form="show"]');
    const formHide = document.querySelectorAll('[data-form="hide"]');

    disableButtons();
    disableOptions();

    formShow.forEach( (element) => {
        element.classList.remove('hide');
    });

    formHide.forEach( (element) => {
        element.classList.add('hide');
    });
}

function submitQuotation() {

    const form = document.querySelector('#form-quotation');
    const button = document.querySelectorAll('#form-quotation [type=submit]');
    const rqst = new ServerRequest();

    rqst.submit('#form-quotation', 'quotation').then( (response) => {

        button.disabled = false;
        $(button).removeClass('c-btn--loading');
        $(button).addClass('c-btn--success').html('Aanvraag verstuurd!');

        form.reset();

        window.setTimeout(function(){
            $(button).removeClass('c-btn--success').html('Aanvragen');
        }, 6000);

    }).catch( (error) => {

        $(button).addClass('c-btn--alert').html('Er is iets misgegaan');

        window.setTimeout(function(){
            $(button).removeClass('c-btn--alert').html('Aanvragen');
            button.disabled = false;
        }, 2500);
    });
}
