import iMask from 'imask';

const inputIban = document.querySelector('.c-iban-mask');

if ( inputIban ) {

	const maskOptions = {
		mask: 'aa00 aaaa 0000 0000 00',

		prepare: function (str) {
			return str.toUpperCase();
		},
	};

	const mask = IMask(inputIban, maskOptions);

}