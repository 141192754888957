function calculateAge(value) {

    value = value.replace(/-/g, '/');

    if ( value.match(/^[(0-9)]{2}\/[(0-9)]{2}\/[(0-9)]{4}$/g) ) {

        const parts = value.split('/');

        value = parts[2] + '/' + parts[1] + '/' + parts[0];
    }

    const birthdate = new Date(value);
    const ageDifMs = Date.now() - birthdate.getTime();
    const ageDate = new Date(ageDifMs);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);

    return age;
}

export default calculateAge;
