import { ServerRequest } from '@nutshell/core';


const form = document.querySelector('[data-funnel-form="questions"]');
const check = document.querySelector('[data-funnel-button="code-check"]');
const input = document.querySelector('[name=code]');
const button = document.querySelector('[data-funnel-form="questions"] button[type=submit]');

var codeIsValid = false;

if ( form ) {

    const questions = document.querySelectorAll('[type="radio"]');

    questions.forEach( (question) => {

        question.addEventListener('change', (event) => {

            const value = event.target.value;
            const additional = document.querySelector('[data-question="' + event.target.name + '"]');

            if ( additional ){

                const checkValue = ( additional.hasAttribute('data-answer-show') ) ? additional.getAttribute('data-answer-show') : 'true';

                if ( value == checkValue ) {
                    additional.classList.remove('hide');
                }
                else {
                    additional.classList.add('hide');
                }
            }
        });
    });

    form.addEventListener('valid', (event) => {

        removeCustomError(input);

        if ( codeIsValid ) {

            const rqst = new ServerRequest();
            button.disabled = true;

            button.classList.add('c-btn--loading');
            button.innerHTML = '<span class="c-loader"></span>';

            rqst.submit('[data-funnel-form="questions"]', 'Funnel:saveQuestions').then( (response) => {

                if ( response.result ) { window.location.href = '/aanvragen/samenvatting/'; }
                else { window.location.href = '/neem-contact-op/'; }

            }).catch( (e) => {

                button.classList.add('c-btn--alert');
                button.classList.remove('c-btn--loading');
                button.innerText = 'Formulier kon niet worden verzonden';

                setTimeout( () => {
                    button.innerText = 'Volgende stap';
                    button.classList.remove('c-btn--alert');
                    button.disabled = false;
                }, 6000);
            });
        }
        else {
            setCustomError(input);
        }
    });

    form.addEventListener('invalid', function() {

        button.classList.add('c-btn--alert');
        button.classList.remove('c-btn--loading');
        button.innerText = 'Formulier is niet volledig ingevuld';

        setTimeout( () => {
            button.innerText = 'Volgende stap';
            button.classList.remove('c-btn--alert');
            button.disabled = false;
        }, 6000);
    });
}

/**
 * Check if code and license place is valid
 */
if ( check ) {


    if ( input ){

        check.addEventListener('click', (event) => {
            event.preventDefault();
            codeCheck(check, input.value);
        });

        input.addEventListener('keyup', (event) => {

            codeIsValid = false;

            check.classList.remove('c-btn--loading');
            check.classList.remove('c-btn--success');
            check.classList.remove('c-btn--error');

            check.innerText = 'Meldcode valideren';
        });

        if ( input.value != "" ) {
            codeCheck(check, input.value);
        }
    }
}

function codeCheck( button, value ) {

    const rqst = new ServerRequest();

    button.disabled = true;
    button.classList.add('c-btn--loading');
    button.innerHTML = '<span class="c-loader"></span>';

    rqst.post('Funnel:codeCheck', { code: value }).then( (response) => {

        setTimeout( () => {
            button.disabled = false;
            button.classList.add('c-btn--success');
            button.classList.remove('c-btn--loading');
            button.innerText = 'Meldcode is valide';

            codeIsValid = true;

        }, 1000);

    }).catch( (e) => {

        setTimeout( () => {

            button.classList.add('c-btn--alert');
            button.classList.remove('c-btn--loading');
            button.innerText = 'Meldcode is invalide';

            setTimeout( () => {
                button.innerText = 'Meldcode valideren';
                button.classList.remove('c-btn--alert');
                button.disabled = false;
            }, 6000);

        }, 1000);
    });
}

function removeCustomError(element) {

    element.setAttribute('data-valid', '');
    element.setAttribute('aria-valid', true);

    element.removeAttribute('data-invalid');
    element.removeAttribute('aria-invalid');

    element.parentNode.classList.add('is-valid');
    element.parentNode.classList.remove('is-invalid');

    const errorField = element.parentNode.querySelector('[data-form-error-for]');

    if ( errorField ) {
        errorField.classList.remove('is-visible');
    }
}

function setCustomError(element) {

    element.setAttribute('data-invalid', '');
    element.setAttribute('aria-invalid', true);

    element.removeAttribute('data-valid');
    element.removeAttribute('aria-valid');

    element.parentNode.classList.add('is-invalid');
    element.parentNode.classList.remove('is-valid');

    const errorField = element.parentNode.querySelector('[data-form-error-for]');

    if ( errorField ) {
        errorField.classList.add('is-visible');
    }
}
