import { ServerRequest } from '@nutshell/core';

const button = document.querySelector('[data-funnel-confirm]');

if ( button ) {

    button.addEventListener('click', () => {

        const rqst = new ServerRequest();

        button.disabled = true;

        button.classList.add('c-btn--loading');
        button.innerHTML = '<span class="c-loader"></span>';

        rqst.post('Funnel:confirm').then( () => {

            window.location.href = '/aanvraag-is-gelukt/';

        }).catch( () => {

            button.classList.add('c-btn--alert');
            button.classList.remove('c-btn--loading');
            button.innerText = 'Formulier kon niet worden verzonden';

            setTimeout( () => {
                button.innerText = 'Volgende stap';
                button.classList.remove('c-btn--alert');
                button.disabled = false;
            }, 6000);
        })
    })
}
