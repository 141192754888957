import IMask from 'imask';

const inputDate = document.querySelector('.c-datemask');

if ( inputDate ) {

	if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
	 	// console.log('mobiel');

		var value = inputDate.value;

		if ( value.match(/^[(0-9)]{2}-[(0-9)]{2}-[(0-9)]{4}$/g) ) {
	        const parts = value.split('-');
	        inputDate.value = parts[2] + '-' + parts[1] + '-' + parts[0];
		}

	 	inputDate.type = 'date';

	} else {
		inputDate.type = 'text';

		var overwriteMask = IMask( inputDate,
			{
  				mask: 'd-m-Y',
				blocks: {
					d: {
						mask: IMask.MaskedRange,
						from: 1,
						to: 31,
						maxLength: 2,
					},
					m: {
						mask: IMask.MaskedRange,
						from: 1,
						to: 12,
						maxLength: 2,
					},
					Y: {
						mask: IMask.MaskedRange,
						from: 1900,
						to: 9999,
					}
				},
				overwrite: true,
				autofix: true
			}
		);
	}
}
