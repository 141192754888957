import { ServerRequest } from '@nutshell/core';

const form = document.querySelector('[data-funnel-form="more-coverage"]');
const watchInput = document.querySelectorAll('[data-watch]');
const accident = document.querySelector('[name="occupant_accidents"]');
const sumSelect = document.querySelector('[name="occupant_accidents_sum"]');

if ( form ) {

    const button = form.querySelector('[type="submit"]');

    form.addEventListener('valid', (event) => {

        event.preventDefault();

        const rqst = new ServerRequest();
        const option = sumSelect.options[sumSelect.selectedIndex];

        button.disabled = true;
        button.classList.add('c-btn--loading');
        button.innerHTML = '<span class="c-loader"></span>';

        var params = null;

        if ( accident.checked ) {

            params = {
                occupant_accidents_invalidity: option.getAttribute('data-sum-invalidity'),
                occupant_accidents_passing: option.getAttribute('data-sum-passing')
            };
        }

        rqst.submit('[data-funnel-form="more-coverage"]', 'Funnel:addCoverage', params).then( (response) => {

            button.disabled = false;
            window.location.href = '/aanvragen/persoonsgegevens/';

        }).catch( (e) => {

            button.classList.add('c-btn--alert');
            button.classList.remove('c-btn--loading');
            button.innerText = 'Formulier kon niet worden verzonden';

            setTimeout( () => {
                button.innerText = 'Volgende stap';
                button.classList.remove('c-btn--alert');
                button.disabled = false;
            }, 6000);
        });
    });
}

if ( sumSelect ) {

    sumSelect.addEventListener('change', () => {

        const container = sumSelect.closest('[data-module]');
        const option = sumSelect.options[sumSelect.selectedIndex];
        const target = document.querySelector('[data-target-costs]');
        const costs = option.getAttribute('data-costs');

        target.innerHTML = '&euro; ' + costs;
    });

    const ev = document.createEvent('Event');
	ev.initEvent('change', true, false);
    
    sumSelect.dispatchEvent(ev);
}

if ( watchInput ) {

    watchInput.forEach( (input) => {

        input.addEventListener('change', () => {

            const id = input.id;
            const container = document.querySelector('[data-module="' + id + '"]');

            if ( container )  {

                if ( input.checked ) {
                    container.classList.remove('hide');
                }
                else {
                    container.classList.add('hide');

                    container.querySelectorAll('input[type=checkbox]').forEach( (element) => {
                        element.checked = false;
                    })
                }
            }
        });
    });
}
