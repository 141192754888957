import $ from 'jquery';

(function () {

	// Youtube
	var youtube = document.querySelectorAll(".youtube-lazy");

	for (var i = 0; i < youtube.length; i++) {

		var source = "https://img.youtube.com/vi/" + youtube[i].dataset.id + "/maxresdefault.jpg";

		if (typeof (youtube[i].dataset.placeholder) !== 'undefined' && youtube[i].dataset.placeholder !== '') {

			source = youtube[i].dataset.placeholder;
		}

		var image = new Image();
		image.src = source;
		image.addEventListener("load", function () {
			youtube[i].appendChild(image);
		}(i));

		youtube[i].addEventListener("click", function () {

			var iframe = document.createElement("iframe");

			iframe.setAttribute("loading", "lazy");
			iframe.setAttribute("frameborder", "0");
			iframe.setAttribute("allowfullscreen", "");
			iframe.setAttribute("src", "https://www.youtube.com/embed/" + this.dataset.id + "?rel=0&showinfo=0&autoplay=1");

			this.innerHTML = "";
			this.appendChild(iframe);
		});
	};



	// Vimeo
	var vimeo = document.querySelectorAll('.vimeo-lazy');

	for (i = 0; i < vimeo.length; i++) {

		if (typeof (vimeo[i].dataset.placeholder) !== 'undefined' && vimeo[i].dataset.placeholder !== '') {

			$('.vimeo-lazy').append('<img src="' + vimeo[i].dataset.placeholder + '"/>');
		}
		else {
			// Get the thumbnail image from api..
			$.getJSON('https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + vimeo[i].dataset.id, { format: "json", width: "640" }, function (data) {
				$('.vimeo-lazy').append('<img src="' + data.thumbnail_url + '"/>');
			});
		}

		// else {
		// 	$.getJSON('https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + vimeo[i].dataset.embed, { format: "json", width: "640" }, function (data) {
		// 		$('.vimeo-lazy[data-embed="' + data.video_id + '"]').append('<img src="' + data.thumbnail_url + '"/>');
		// 	});
		// }

		vimeo[i].addEventListener("click", function () {

			var iframe = document.createElement("iframe");

			iframe.setAttribute("loading", "lazy");
			iframe.setAttribute("frameborder", "0");
			iframe.setAttribute("allowfullscreen", "");
			iframe.src = 'https://player.vimeo.com/video/' + this.dataset.id + '?autoplay=1&autopause=0';

			this.innerHTML = "";
			this.appendChild(iframe);
		});
	};

})();