const button = document.querySelector('[data-chat="trigger"]');

if ( button ) {

    button.addEventListener('click', ( event ) => {

        event.preventDefault();
        const iframes = document.querySelectorAll('iframe');

        iframes.forEach( (frame) => {

            if ( frame.getAttribute('title') == "chat widget" ) {

                const framedocument = frame.contentWindow.document;
                const trigger = framedocument.querySelector('#tawkchat-status-text-container');

                if ( trigger ) {
                    trigger.click();
                }
            }
        });
    });
}
