import $ from 'jquery';

//nav dropdown time-out (user friendly)

var timer;

$('.c-nav__trigger').on('mouseover', function() {

	$('.c-nav__trigger').removeClass('open');
    clearTimeout(timer);
    $(this).addClass('open');

}).on('mouseleave', function() {

    timer = setTimeout(
        closeSubmenu
        , 300);

});

function closeSubmenu() {
    $('.c-nav__trigger').removeClass('open');
}