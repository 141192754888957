import { ServerRequest } from '@nutshell/core';

const form = document.querySelector('[data-funnel-form="personal-data"]');

if ( form ) {

    const button = form.querySelector('button[type=submit]');
    const street = form.querySelector('#street');
    const residence = form.querySelector('#residence');
    const rqst = new ServerRequest();

    /**
     * Handle submit of form
     */
    form.addEventListener('valid', (event) => {

        event.preventDefault();


        button.disabled = true;
        button.classList.add('c-btn--loading');
        button.innerHTML = '<span class="c-loader"></span>';

        rqst.submit('[data-funnel-form="personal-data"]', 'Funnel:savePersonalData').then( (response) => {
            button.disabled = false;
            window.location.href = '/aanvragen/aanvullende-gegevens/';
        }).catch( (e) => {

            button.classList.add('c-btn--alert');
            button.classList.remove('c-btn--loading');
            button.innerText = 'Formulier kon niet worden verzonden';

            setTimeout( () => {
                button.innerText = 'Volgende stap';
                button.classList.remove('c-btn--alert');
                button.disabled = false;
            }, 6000);
        });
    });

    /**
     * Autofill address data
     */
    if ( street.value == '' && residence.value == '' ){

        rqst.get('Funnel:getAddress').then( ( {result} ) => {
            street.value = result.street;
            residence.value = result.city;
        }).catch( (e) => {
            street.value = '';
            residence.value = '';
        });

    }

    form.addEventListener('invalid', function() {

        button.classList.add('c-btn--alert');
        button.classList.remove('c-btn--loading');
        button.innerText = 'Formulier is niet volledig ingevuld';

        setTimeout( () => {
            button.innerText = 'Volgende stap';
            button.classList.remove('c-btn--alert');
            button.disabled = false;
        }, 6000);
    });
}
