require('core-js');

/**
 * Polyfill for DomElements
 */
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector ||
                              Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;

    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

/*******
* inmport all used javascript
*******/
import 'foundation-sites';

import $ from 'jquery';
import { Setup, Context } from '@nutshell/core';

/*******
* Functions
*******/
import verifyIban from './functions/iban.js';
import calculateAge from './functions/age.js';

/*******
* Add jQuery to the site
*******/
window.$ = $;
window.jQuery = $;

window.Context = Context;

/*******
* Apply javascript of the core
*******/
Setup.init({
    validateOptions: {
        customValidators: {
            zipcode: /^[1-9][0-9]{3}[A-Z]{2}$/,
            code: /^[0-9]{4}$/,
            age: function($el){
                const age = calculateAge($el.val());
                return ( parseInt(age) >= 18 && parseInt(age) <= 110 );
            },
            years: function($el){
                const age = calculateAge($el.data('birthdate'));
                const years = $el.val();

                return ( ( parseInt(age) - 18 ) >= parseInt(years) );
            },
            iban: function($el) {
                return verifyIban($el.val());
            }
        }
    }
});

/**
* Node modules
**/
require('cookieconsent');
require('sharer.js');
require('imask');


/*******
* Modules
*******/
import './website/autocorrect.js';
import './website/collapse.js';
import './website/cookieConsent.js';
import './website/contact.js';
import './website/dateMask.js';
//import './website/formQuotation.js';
import './website/headerSticky.js';
import './website/ibanMask.js';
import './website/nav.js';
import './website/navDropdown.js';
import './website/chat.js';
import './website/videoVimeoLazy.js';
import './website/widgetCompareEsos.js';

/*******
* funnel
*******/
import './website/funnel/license-plate-check.js';
import './website/funnel/addition-information.js';
import './website/funnel/compare-insurance.js';
import './website/funnel/personal-data.js';
import './website/funnel/additional-data.js';
import './website/funnel/more-coverage.js';
import './website/funnel/questions.js';
import './website/funnel/resume.js';



/**
* Foundation
**/
$(document).foundation();
