import $ from 'jquery';

//sticky header

var num = 360;

$(window).bind('scroll', function () {
    if ($(window).scrollTop() > num) {
    	
    	//home page
        $('.c-header--home .c-header__top').addClass('c-header__top--fixed');
        $('.c-header--home .c-header__top .c-nav').addClass('c-nav--light');

    	//default page
    	$('.o-template--default').addClass('pt--default');
        $('.c-header--default').addClass('c-header--fixed');

    } else {
    	
        //home page
        $('.c-header--home .c-header__top').removeClass('c-header__top--fixed');
        $('.c-header--home .c-header__top .c-nav').removeClass('c-nav--light');

    	//default page
    	$('.o-template--default').removeClass('pt--default');
        $('.c-header--default').removeClass('c-header--fixed');
        
    }
});