import { ServerRequest} from '@nutshell/core';

const form = document.querySelector('#form-contact');

if ( form ) {

    const button = form.querySelector('button[type=submit]');

    form.addEventListener('valid', (event) => {

        event.preventDefault();

        /**
         * Submit form when is validated by Abide.
         * Form needs an attribute `data-form-validate` to trigger the `valid` event.
         * If the form doesn't use the core validator, use the event `submit`
         */
        const rqst = new ServerRequest();

        button.disabled = true;
        $(button).addClass('c-btn--loading').html('<span class="c-loader"></span>');

        rqst.submit('#form-contact', 'contact').then( (response) => {

            button.disabled = false;
            
            $(button).removeClass('c-btn--loading');
            $(button).addClass('c-btn--success').html('Bericht verstuurd!');

            form.reset();

            window.setTimeout(function(){
                $(button).removeClass('c-btn--success').html('Bericht versturen');
            }, 2500);

            return true;

        }).catch( () => {

            button.disabled = false;

            $(button).addClass('c-btn--alert').html('Er is iets misgegaan');

            window.setTimeout(function(){
                $(button).removeClass('c-btn--alert').html('Bericht versturen');
            }, 2500);
        });
    });
}
