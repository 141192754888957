// Cookie Consent

window.addEventListener("load", function(){

	if ( window.cookieconsent ) {
		window.cookieconsent.initialise({
			"palette": {
				"popup": {
					"background": "#000000",
					"text": "#ffffff"
				},
				"button": {
					"background": "#6A00CA",
					"text": "#ffffff"
				}
			},
			"content": {
				"message": "Wij gebruiken cookies om uw gebruikerservaring te verbeteren en statistieken bij te houden. Meer informatie vindt u in ons",
				"dismiss": "Sluit",
				"link": "privacy policy",
				"href": "/privacy-policy",
				"target": '_self'
			}
		});
	}
});