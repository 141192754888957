import { ServerRequest} from '@nutshell/core';

const form = document.querySelector('[data-funnel-form="license_plate"]');
const button = document.querySelector('[data-funnel-form="license_plate"] button[type=submit]');
const licensePlate = document.querySelector('.c-form-element--license-plate');
const zip = document.querySelector('.c-form-element--zip');
const housenumber = document.querySelector('.c-form-element--housenumber');

if ( form ) {

    form.addEventListener('valid', (event) => {

        event.preventDefault();

        const rqst = new ServerRequest();

        button.disabled = true;
        button.classList.add('c-btn--loading');
        button.innerHTML = '<span class="c-loader"></span>';

        rqst.submit('[data-funnel-form="license_plate"]', 'Funnel:licensePlateCheck').then( (response) => {
            button.disabled = false;
            window.location.href = '/check/aanvullende-informatie/';
        }).catch( (e, message) => {

            button.classList.add('c-btn--alert');
            button.classList.remove('c-btn--loading');
            button.innerText = 'Niet gevonden';

            console.log("error", e);
            console.log("message", message);

            // bij foutief postcode/adres
            if(e.message !== undefined && e.message === "Address unavailable"){
                zip.classList.add('is-invalid');
                housenumber.classList.add('is-invalid');
            }
            
            // bij foutief kenteken
            if(e.message !== undefined && e.message === "License plate unavailable"){
                licensePlate.classList.add('is-invalid');
            }
            
            // Bij beide foutief
            if(e.message !== undefined && e.message === "Address and license plate unavailable"){
                zip.classList.add('is-invalid');
                housenumber.classList.add('is-invalid');
                licensePlate.classList.add('is-invalid');
            }
            
            setTimeout( () => {
                button.innerText = 'Vergelijk nu';
                button.classList.remove('c-btn--alert');
                button.disabled = false;
                zip.classList.remove('is-invalid');
                housenumber.classList.remove('is-invalid');
                licensePlate.classList.remove('is-invalid');
            }, 6000);
        });
    });
}
